/********************************************************************************/
//   Border Radius
/********************************************************************************/
.rounded-full {
	border-radius: 9999px;
}
.rounded-lg {
	border-radius: 12px;
}
.rounded-md {
	border-radius: 8px;
}
.rounded-sm {
	border-radius: 4px;
}
.rounded-none {
	border-radius: 0;
}



/********************************************************************************/
//   Text Color Gray
/********************************************************************************/
.text-gray-100 {
    color: #f7fafc;
}
.text-gray-200 {
    color: #edf2f7;
}
.text-gray-300 {
    color: #e2e8f0;
}
.text-gray-400 {
    color: #cbd5e0;
}
.text-gray-500 {
    color: #a0aec0;
}
.text-gray-600 {
    color: #718096;
}
.text-gray-700 {
    color: #4a5568;
}
.text-gray-800 {
    color: #2d3748;
}
.text-gray-900 {
    color: #1a202c;
}
.text-gray-custom-1 {
    color: #374151;
}



/********************************************************************************/
//   Text Color White
/********************************************************************************/

.text-white {
    color: #fff;
}


/********************************************************************************/
//   Text Color White
/********************************************************************************/
.text-black {
    color: #000;
}


/********************************************************************************/
//   Text Color Red
/********************************************************************************/
.text-red-100 {
    color: #fff5f5;
}
.text-red-200 {
    color: #fed7d7;
}
.text-red-300 {
    color: #feb2b2;
}
.text-red-400 {
    color: #fc8181;
}
.text-red-500 {
    color: #f56565;
}
.text-red-600 {
    color: #e53e3e;
}
.text-red-700 {
    color: #c53030;
}
.text-red-800 {
    color: #9b2c2c;
}
.text-red-900 {
    color: #742a2a;
}

/********************************************************************************/
//   Text Color Orange
/********************************************************************************/

.text-orange-100 {
    color: #fffaf0;
}
.text-orange-200 {
    color: #feebc8;
}
.text-orange-300 {
    color: #fbd38d;
}
.text-orange-400 {
    color: #f6ad55;
}
.text-orange-500 {
    color: #ed8936;
}
.text-orange-600 {
    color: #dd6b20;
}
.text-orange-700 {
    color: #c05621;
}
.text-orange-800 {
    color: #9c4221;
}
.text-orange-900 {
    color: #7b341e;
}


/********************************************************************************/
//   Text Color Yellow
/********************************************************************************/

.text-yellow-100 {
    color: #fffff0;
}
.text-yellow-200 {
    color: #fefcbf;
}
.text-yellow-300 {
    color: #faf089;
}
.text-yellow-400 {
    color: #f6e05e;
}
.text-yellow-500 {
    color: #ecc94b;
}
.text-yellow-600 {
    color: #d69e2e;
}
.text-yellow-700 {
    color: #b7791f;
}
.text-yellow-800 {
    color: #975a16;
}
.text-yellow-900 {
    color: #744210;
}


/********************************************************************************/
//   Text Color Green
/********************************************************************************/

.text-green-100 {
    color: #f0fff4;
}
.text-green-200 {
    color: #c6f6d5;
}
.text-green-300 {
    color: #9ae6b4;
}
.text-green-400 {
    color: #68d391;
}
.text-green-500 {
    color: #48bb78;
}
.text-green-600 {
    color: #38a169;
}
.text-green-700 {
    color: #2f855a;
}
.text-green-800 {
    color: #276749;
}
.text-green-900 {
    color: #22543d;
}



.font-bold {
    font-weight: 700;
}
.font-semibold {
    font-weight: 600;
}
.font-normal {
    font-weight: 400;
}
.font-light {
    font-weight: 300;
}
.font-thin {
    font-weight: 100;
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-justify {
    text-align: justify;
}
.text-xs {
    font-size: 0.75rem;
}
.text-sm {
    font-size: 0.875rem;
}
.text-base {
    font-size: 1rem;
}
.text-lg {
    font-size: 1.125rem;
}
.text-xl {
    font-size: 1.25rem;
}
.text-2xl {
    font-size: 1.5rem;
}
.text-3xl {
    font-size: 1.875rem;
}
.text-4xl {
    font-size: 2.25rem;
}
.text-5xl {
    font-size: 3rem;
}
.w-full {
    width: 100%;
}
.w-1-2 {
    width: 50%;
}
.w-1-3 {
    width: 33.333333%;
}
.w-2-3 {
    width: 66.666667%;
}
.w-1-4 {
    width: 25%;
}
.w-2-4 {
    width: 50%;
}
.w-3-4 {
    width: 75%;
}
.w-1-5 {
    width: 20%;
}
.w-2-5 {
    width: 40%;
}
.w-3-5 {
    width: 60%;
}
.w-4-5 {
    width: 80%;
}
.w-1-6 {
    width: 16.666667%;
}
.w-2-6 {
    width: 33.333333%;
}
.w-3-6 {
    width: 50%;
}
.w-4-6 {
    width: 66.666667%;
}
.w-5-6 {
    width: 83.333333%;
}
.w-1-12 {
    width: 8.333333%;
}
.w-2-12 {
    width: 16.666667%;
}
.w-3-12 {
    width: 25%;
}   
.flex {
    display: flex;
}
.flex-row {
    flex-direction: row;
}
.flex-col {
    flex-direction: column;
}
.justify-start {
    justify-content: flex-start;
}
.justify-end {
    justify-content: flex-end;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.justify-around {
    justify-content: space-around;
}
.items-start {
    align-items: flex-start;
}
.items-end {
    align-items: flex-end;
}
.items-center {
    align-items: center;
}
.items-baseline {
    align-items: baseline;
}
.items-stretch {
    align-items: stretch;
}
.flex-wrap {
    flex-wrap: wrap;
}
.flex-no-wrap {
    flex-wrap: nowrap;
}
.flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}
.flex-grow {
    flex-grow: 1;
}
.flex-shrink {
    flex-shrink: 1;
}
.flex-no-shrink {
    flex-shrink: 0;
}
.flex-auto {
    flex: 1 1 auto;
}
.flex-initial {
    flex: 0 1 auto;
}
.flex-none {
    flex: none;
}



/********************************************************************************/
//   Margins
/********************************************************************************/
.ml-auto {
    margin-left: auto;
}
.mr-auto {
    margin-right: auto;
}
.mt-auto {
    margin-top: auto;
}
.mb-auto {
    margin-bottom: auto;
}
.m-auto {
    margin: auto;
}






/********************************************************************************/
//   Orders
/********************************************************************************/

.order-first {
    order: -9999;
}
.order-last {
    order: 9999;
}
.order-none {
    order: 0;
}
.order-1 {
    order: 1;
}
.order-2 {
    order: 2;
}
.order-3 {
    order: 3;
}
.order-4 {
    order: 4;
}
.order-5 {
    order: 5;
}
.order-6 {
    order: 6;
}
.order-7 {
    order: 7;
}
.order-8 {
    order: 8;
}
.order-9 {
    order: 9;
}
.order-10 {
    order: 10;
}





/********************************************************************************/
//   Paddings
/********************************************************************************/

.p-0 {
    padding: 0;
}
.p-1 {
    padding: 0.25rem;
}
.p-2 {
    padding: 0.5rem;
}
.p-3 {
    padding: 0.75rem;
}
.p-4 {
    padding: 1rem;
}
.p-5 {
    padding: 1.25rem;
}
.p-6 {
    padding: 1.5rem;
}
.p-7 {
    padding: 1.75rem;
}
.p-8 {
    padding: 2rem;
}
.p-9 {
    padding: 2.25rem;
}
.p-10 {
    padding: 2.5rem;
}
.p-11 {
    padding: 2.75rem;
}
.p-12 {
    padding: 3rem;
}
.p-16 {
    padding: 4rem;
}
.p-20 {
    padding: 5rem;
}
.p-24 {
    padding: 6rem;
}
.p-32 {
    padding: 8rem;
}
.p-40 {
    padding: 10rem;
}
.p-48 {
    padding: 12rem;
}
.p-56 {
    padding: 14rem;
}
.p-64 {
    padding: 16rem;
}
.px-0 {
    padding-left: 0;
    padding-right: 0;
}
.px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}   
.px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
}
.px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
}
.px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
}
.px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}
.px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
}
.px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
}
.px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
}
.px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
}
.px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
}
.px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
}
.px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
}
.px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
}
.px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
}
.px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
}
.py-0 {
    padding-top: 0;
    padding-bottom: 0;
}
.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}
.py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
}
.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
}
.py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}
.py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
}
.py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
}
.py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
}
.py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
}
.py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
}
.py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
}
.py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
}
.py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
}
.pt-0 {
    padding-top: 0;
}
.pt-1 {
    padding-top: 0.25rem;
}
.pt-2 {
    padding-top: 0.5rem;
}
.pt-3 {
    padding-top: 0.75rem;
}
.pt-4 {
    padding-top: 1rem;
}
.pt-5 {
    padding-top: 1.25rem;
}
.pt-6 {
    padding-top: 1.5rem;
}
.pt-7 {
    padding-top: 1.75rem;
}
.pt-8 {
    padding-top: 2rem;
}
.pt-9 {
    padding-top: 2.25rem;
}
.pt-10 {
    padding-top: 2.5rem;
}
.pt-11 {
    padding-top: 2.75rem;
}
.pt-12 {
    padding-top: 3rem;
}
.pt-16 {
    padding-top: 4rem;
}
.pt-20 {
    padding-top: 5rem;
}
.pt-24 {
    padding-top: 6rem;
}
.pt-32 {
    padding-top: 8rem;
}
.pt-40 {
    padding-top: 10rem;
}
.pt-48 {
    padding-top: 12rem;
}
.pt-56 {
    padding-top: 14rem;
}
.pt-64 {
    padding-top: 16rem;
}



/********************************************************************************/
//   Background Color Green
/********************************************************************************/

.bg-green-100 {
    background-color: #f0fff4;
}
.bg-green-200 {
    background-color: #c6f6d5;
}
.bg-green-300 {
    background-color: #9ae6b4;
}
.bg-green-400 {
    background-color: #68d391;
}
.bg-green-500 {
    background-color: #48bb78;
}
.bg-green-600 {
    background-color: #38a169;
}
.bg-green-700 {
    background-color: #2f855a;
}
.bg-green-800 {
    background-color: #276749;
}
.bg-green-900 {
    background-color: #22543d;
}


/********************************************************************************/
//   Background Color Red
/********************************************************************************/

.bg-red-100 {
    background-color: #fff5f5;
}
.bg-red-200 {
    background-color: #fed7d7;
}
.bg-red-300 {
    background-color: #feb2b2;
}
.bg-red-400 {
    background-color: #fc8181;
}
.bg-red-500 {
    background-color: #f56565;
}
.bg-red-600 {
    background-color: #e53e3e;
}
.bg-red-700 {
    background-color: #c53030;
}
.bg-red-800 {
    background-color: #9b2c2c;
}
.bg-red-900 {
    background-color: #742a2a;
}


/********************************************************************************/
//   Background Color Yellow
/********************************************************************************/

.bg-yellow-100 {
    background-color: #fffff0;
}
.bg-yellow-200 {
    background-color: #fefcbf;
}
.bg-yellow-300 {
    background-color: #faf089;
}
.bg-yellow-400 {
    background-color: #f6e05e;
}
.bg-yellow-500 {
    background-color: #ecc94b;
}
.bg-yellow-600 {
    background-color: #d69e2e;
}
.bg-yellow-700 {
    background-color: #b7791f;
}
.bg-yellow-800 {
    background-color: #975a16;
}
.bg-yellow-900 {
    background-color: #744210;
}

/********************************************************************************/
//   Background Color Blue
/********************************************************************************/

.bg-blue-100 {
    background-color: #ebf8ff;
}
.bg-blue-200 {
    background-color: #bee3f8;
}
.bg-blue-300 {
    background-color: #90cdf4;
}
.bg-blue-400 {
    background-color: #63b3ed;
}
.bg-blue-500 {
    background-color: #4299e1;
}
.bg-blue-600 {
    background-color: #3182ce;
}
.bg-blue-700 {
    background-color: #2b6cb0;
}
.bg-blue-800 {
    background-color: #2c5282;
}
.bg-blue-900 {
    background-color: #2a4365;
}
.bg-blue-custom {
    background-color: #0966ff;
}



/********************************************************************************/
//   Font Weight
/********************************************************************************/


.fw-0 {
    font-weight: 0;
}
.fw-100 {
    font-weight: 100;
}
.fw-200 {
    font-weight: 200;
}
.fw-300 {
    font-weight: 300;
}
.fw-400 {
    font-weight: 400;
}
.fw-500 {
    font-weight: 500;
}
.fw-600 {
    font-weight: 600;
}
.fw-700 {
    font-weight: 700;
}
.fw-800 {
    font-weight: 800;
}
.fw-900 {
    font-weight: 900;
}



/********************************************************************************/
//   Overflows
/********************************************************************************/

.overflow-auto {
    overflow: auto;
}
.overflow-hidden {
    overflow: hidden;
}
.overflow-visible {
    overflow: visible;
}
.overflow-scroll {
    overflow: scroll;
}
.overflow-x-auto {
    overflow-x: auto;
}
.overflow-x-hidden {
    overflow-x: hidden;
}
.overflow-x-visible {
    overflow-x: visible;
}
.overflow-x-scroll {
    overflow-x: scroll;
}
.overflow-y-auto {
    overflow-y: auto;
}
.overflow-y-hidden {
    overflow-y: hidden;
}
.overflow-y-visible {
    overflow-y: visible;
}
.overflow-y-scroll {
    overflow-y: scroll;
}



.gap-0 {
    gap: 0;
}
.gap-1 {
    gap: 0.25rem;
}
.gap-2 {
    gap: 0.5rem;
}
.gap-3 {
    gap: 0.75rem;
}
.gap-4 {
    gap: 1rem;
}
.gap-5 {
    gap: 1.25rem;
}
.gap-6 {
    gap: 1.5rem;
}
.gap-7 {
    gap: 1.75rem;
}
.gap-8 {
    gap: 2rem;
}
.gap-9 {
    gap: 2.25rem;
}
.gap-10 {
    gap: 2.5rem;
}
.gap-11 {
    gap: 2.75rem;
}
.gap-12 {
    gap: 3rem;
}
.gap-16 {
    gap: 4rem;
}
.gap-20 {
    gap: 5rem;
}
.gap-24 {
    gap: 6rem;
}
.gap-32 {
    gap: 8rem;
}